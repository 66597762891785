<template>
  <div class="claim-editor">

    <validation-provider
      name="claim"
      rules="required"
      v-slot="{ errors }"
    >
      <v-text-field
        disabled
        dense
        outlined
        v-model="form.code"
        name="claim"
        label="Код заявки"
        :error-messages="errors[0]"
        :hide-details="form.useParentChannelId"
      />
    </validation-provider>

    <div v-if="form.useParentChannelId" class="caption mb-5">
      Отправляется в группу родителя
    </div>


    <validation-provider
      rules="required"
      name="title"
      v-slot="{ errors }"
    >
      <v-text-field
        :autofocus="autofocus"
        v-model="form.title"
        dense
        outlined
        name="title"
        label="Название"
        :error-messages="errors[0]"
      />
    </validation-provider>

    <validation-provider
      v-if="!form.useParentChannelId"
      name="channelId"
      rules="required"
      v-slot="{ errors }"
    >
      <v-text-field
        v-model="form.channelId"
        dense
        outlined
        name="channelId"
        label="Номер группы"
        :error-messages="errors[0]"
      />
    </validation-provider>

    <v-textarea
      v-model="form.comment"
      dense
      outlined
      name="comment"
      label="Комментарий"
    />

  </div>
</template>


<script>
import isEqual from 'lodash/isEqual'
import ClaimSelect from './ClaimSelect.vue'
import requestFormService from '@/services/request-form'

export default {
  components: {
    ClaimSelect
  },
  props: {
    autofocus: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      form: requestFormService.createModel(this.value)
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler (value) {
        if (isEqual(value, this.form)) return
        this.form = requestFormService.createModel(this.value)
      }
    },
    form: {
      deep: true,
      handler (form) {
        this.$emit('input', form)
      }
    }
  },
}
</script>
