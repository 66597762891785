<template>
  <div class="doctor-promo-form">
    <validation-provider slim rules="required" name="title" v-slot="{ errors }">
      <v-text-field
        v-model="form.title"
        name="title"
        label="Название"
        :error-messages="errors[0]"
      />
    </validation-provider>

    <validation-provider slim rules="required" name="buttonText" v-slot="{ errors }">
      <v-text-field
        v-model="form.buttonText"
        name="buttonText"
        label="Главная кнопка"
        :error-messages="errors"
      />
    </validation-provider>

    <v-row>
      <v-col lg="3">
        <v-checkbox
          dense
          label="Принимать заявки"
          v-model="form.canSendRequest"
          hide-details
        />
      </v-col>

      <v-col>
        <validation-provider slim rules="required" name="requestButtonLabel" v-slot="{ errors }">
          <v-text-field
            block
            dense
            name="requestButtonLabel"
            v-model="form.requestButtonLabel"
            label="Кнопка заявки"
            :error-messages="errors[0]"
          />
        </validation-provider>
      </v-col>
    </v-row>

    <validation-provider name="content" slim rules="required" v-slot="{ errors }" >
      <div>
        <quill
          :options="toolbarConfig"
          v-model="form.content"
        />
        <div class="red--text" style="margin-top: -8px">
          {{ errors[0] }}
        </div>
      </div>
    </validation-provider>
  </div>
</template>

<script>
import { QUILL_DOCTOR_PROMO } from '../../components/elements/quilljs/configs'

export class DoctorPromoModel {
  constructor (options = {}) {
    return Object.assign({
      title: null,
      content: null,
      buttonText: null,
      requestButtonLabel: null,
      canSendRequest: true,
    }, options || {})
  }
}

export default {
  props: {
    value: {}
  },
  data () {
    return {
      toolbarConfig: QUILL_DOCTOR_PROMO,
      form: new DoctorPromoModel(this.value)
    }
  },
  watch: {
    form: {
      deep: true,
      handler (form) {
        this.$emit('input', form)
      }
    }
  }
}
</script>
