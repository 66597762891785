<template>
  <v-select
    v-on="$listeners"
    v-bind="attrs"
  >
    <template v-slot:item="{ item }">
      <div style="width: 100%">
        <div class="d-flex justify-space-between" style="grid-gap: 20px">
          <span>
            {{ item.title }}
          </span>
          <span class="caption">
            {{ item.code }}
          </span>
        </div>
        <div class="caption">
          {{ item.useParentChannelId ? `ChannelID родителя` : `channelId: ${item.channelId}` }}
        </div>
      </div>
    </template>
  </v-select>
</template>

<script>
import requestFormService from '@/services/request-form'

export default {
  data () {
    return {
      loading: false,
      items: []
    }
  },
  computed: {
    attrs () {
      return {
        name: "claim",
        itemText: 'title',
        itemValue: 'code',
        items: this.items,
        loading: this.loading,
        ...this.$attrs,
      }
    },
  },
  mounted () {
    this.load()
  },
  methods: {
    async load (params) {
      let error
      if (this.loading) return
      this.loading = true
      try {
        this.items = await requestFormService.getAll(params)
      } catch (e) {
        error = e
      }
      this.loading = false
      if (error) throw error
    }
  }
}
</script>
