var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"doctor-promo-form"},[_c('validation-provider',{attrs:{"slim":"","rules":"required","name":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"title","label":"Название","error-messages":errors[0]},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}])}),_c('validation-provider',{attrs:{"slim":"","rules":"required","name":"buttonText"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"buttonText","label":"Главная кнопка","error-messages":errors},model:{value:(_vm.form.buttonText),callback:function ($$v) {_vm.$set(_vm.form, "buttonText", $$v)},expression:"form.buttonText"}})]}}])}),_c('v-row',[_c('v-col',{attrs:{"lg":"3"}},[_c('v-checkbox',{attrs:{"dense":"","label":"Принимать заявки","hide-details":""},model:{value:(_vm.form.canSendRequest),callback:function ($$v) {_vm.$set(_vm.form, "canSendRequest", $$v)},expression:"form.canSendRequest"}})],1),_c('v-col',[_c('validation-provider',{attrs:{"slim":"","rules":"required","name":"requestButtonLabel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"block":"","dense":"","name":"requestButtonLabel","label":"Кнопка заявки","error-messages":errors[0]},model:{value:(_vm.form.requestButtonLabel),callback:function ($$v) {_vm.$set(_vm.form, "requestButtonLabel", $$v)},expression:"form.requestButtonLabel"}})]}}])})],1)],1),_c('validation-provider',{attrs:{"name":"content","slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('quill',{attrs:{"options":_vm.toolbarConfig},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}}),_c('div',{staticClass:"red--text",staticStyle:{"margin-top":"-8px"}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }