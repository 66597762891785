<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <item-edit-view
      title="Доктор"
      :subtitle="item ? item.fullName : null"
      :loading="loading"
    >
      <template v-slot:right>
        <v-btn
          right
          color="success"
          :loading="saving"
          @click.prevent="handleSubmit(save)"
        >
          <v-icon small>mdi-content-save</v-icon>
          Сохранить
        </v-btn>
      </template>

      <v-card>
        <v-form v-if="!loading && item">
          <v-tabs v-model="tab" background-color="primary" dark>
            <v-tab key="profile">Профиль</v-tab>
            <v-tab key="claims">Заявки</v-tab>
            <v-tab key="promo">Promo</v-tab>
            <v-tab key="examplesOfWorks">Примеры работ</v-tab>
            <v-tab key="specialities" v-if="item.specialities">Специальности</v-tab>
            <v-tab key="gallery">Галерея</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item key="profile">
              <v-card-text>

                <v-row>
                  <v-col cols="3">
                    <doctor-avatar
                      style="width: 100%"
                      :src="item.avatar ? item.avatar.originalUrl : null"
                      :ratio="1"
                      v-model="item.avatarGravity"
                    />
                  </v-col>
                  <v-col>
                    <p class="control">
                      <label class="checkbox">
                        <input type="checkbox" v-model="item.private">
                        Скрыт
                      </label>
                    </p>
                    <p class="control">
                      <label class="checkbox">
                        <input type="checkbox" v-model="item.allowedToSendRequestForOnlineConsultation">
                        Разрешить отправку запроса на онлайн консультацию
                      </label>
                    </p>

                    <validation-provider
                      v-slot="{ errors }"
                      name="AHealthChat"
                    >
                      <v-text-field
                        v-model="item.telegram"
                        label="AHealthChat (переопределит все остальные контакты)"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row class="mb-8 mt-8">
                  <v-col>
                    <h2 class="mb-2">Meta</h2>
                    <item-meta v-model="item.meta" />
                  </v-col>
                </v-row>

              </v-card-text>
            </v-tab-item>

            <v-tab-item key="claims" eager>
              <v-card-text>
                <claims-list v-model="item.requestForms" />
              </v-card-text>
            </v-tab-item>

            <v-tab-item key="promo">
              <v-card-text>
                <DoctorPromo v-model="item.servicePromo" />
              </v-card-text>
            </v-tab-item>

            <v-tab-item key="examplesOfWorks">
                <v-simple-table class="ma-5">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">До</th>
                        <th class="text-left">После</th>
                        <th class="text-left">Описание</th>
                        <th class="text-left"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <ValidationObserver v-slot="{ handleSubmit }" tag="tr" disabled="true">
                        <td>
                          <ValidationProvider name="before image" rules="required" v-slot="{ validate, errors }">
                            <v-img v-if="newExampleOfWork.before"
                               :src="newExampleOfWork.before.thumbnailUrl"
                               max-height="200"
                               max-width="200"
                               Contain
                               class="ma-2"
                            >
                            </v-img>
                            <span v-if="errors.length" class="v-messages theme--light error--text">{{ errors[0] }}</span>
                            <v-btn
                              @click.stop="choosePhoto(newExampleOfWork, 'before', validate)"
                              small
                            >Выбрать</v-btn>
                          </ValidationProvider>
                        </td>
                        <td>
                          <ValidationProvider name="after image" rules="required" v-slot="{ validate, errors }">
                            <v-img v-if="newExampleOfWork.after"
                               :src="newExampleOfWork.after.thumbnailUrl"
                               max-height="200"
                               max-width="200"
                               Contain
                               class="ma-2"
                            >
                            </v-img>
                            <span v-if="errors.length" class="v-messages theme--light error--text">{{ errors[0] }}</span>
                            <v-btn
                              @click.stop="choosePhoto(newExampleOfWork, 'after', validate)"
                              small
                            >Выбрать</v-btn>
                          </ValidationProvider>
                        </td>
                        <td>
                          <v-textarea
                            v-model="newExampleOfWork.description"
                            auto-grow
                            rows="1"
                          ></v-textarea>
                        </td>
                        <td>
                          <v-btn @click.stop="handleSubmit(addExampleOfWork)" color="success" small>Добавить</v-btn>
                        </td>
                      </ValidationObserver>

                      <tr v-for="exampleOfWork in item.examplesOfWorks" :key="exampleOfWork.uuid">
                        <td>
                          <v-img v-if="exampleOfWork.before"
                                 :src="exampleOfWork.before.thumbnailUrl"
                                 max-height="200"
                                 max-width="200"
                                 Contain
                                 class="ma-2"
                          >
                          </v-img>
                          <v-btn
                            @click="choosePhoto(exampleOfWork, 'before')"
                            small
                          >Поменять</v-btn>
                        </td>
                        <td>
                          <v-img v-if="exampleOfWork.after"
                                 :src="exampleOfWork.after.thumbnailUrl"
                                 max-height="200"
                                 max-width="200"
                                 Contain
                                 class="ma-2"
                          >
                          </v-img>
                          <v-btn
                            @click="choosePhoto(exampleOfWork, 'after')"
                            small
                          >Поменять</v-btn>
                        </td>
                        <td>
                          <v-textarea
                            v-model="exampleOfWork.description"
                            auto-grow
                            rows="1"
                          ></v-textarea>
                        </td>
                        <td>
                          <v-btn @click="removeExampleOfWork(exampleOfWork)" color="error" small>Удалить</v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
            </v-tab-item>
            <v-tab-item key="specialities">

            <specialities v-model="item.specialities"/>

            </v-tab-item>
            <v-tab-item key="gallery">
              <v-card-text>
                <blocks-editor-field-gallery v-model="item.galleryPhotos" />
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card>
    </item-edit-view>
  </ValidationObserver>
</template>

<script>
import api from '../../services/doctors'
import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'
import ItemEditView from '../../components/ItemEditView'
import {QUILL_SIMPLE} from '../../components/elements/quilljs/configs'
import { v4 } from 'uuid'
import Vue from 'vue'
import isFunction from 'lodash/isFunction'
import Specialities from '@/components/Specialities'
import ClaimsList from '@/components/Claim/ClaimsList.vue'
import DoctorPromo from '@/components/Doctor/DoctorPromo.vue'
import BlocksEditorFieldGallery from '@/components/BlocksEditor/BlocksEditorField/BlocksEditorFieldGallery.vue'
import GravityImageManager from '@/components/elements/gravity/GravityImageManager.vue'
import DoctorAvatar from './DoctorAvatar.vue'
import { ItemMeta } from '@/components'

export default {
  components: {
    ItemMeta,
    DoctorAvatar,
    ItemEditView,
    Specialities,
    ClaimsList,
    DoctorPromo,
    BlocksEditorFieldGallery,
  },
  mixins: [createOrUpdateViewMixin(api)],
  data () {
    return {
      tab: null,
      newExampleOfWork: { after: null, before: null, description: null },
      specialities: null
    }
  },
  computed: {
    quill () {
      return QUILL_SIMPLE
    }
  },
  watch: {
    item: function(val) {
    }
  },
  methods: {
    choosePhoto(object, prop, validate = null) {
      this.photoModal = this.openPhotoModal()
      this.photoModal.$once('choose', (image) => {
        Vue.set(object, prop, image)
        if (isFunction(validate)) {
          validate(image)
        }
      })
    },
    addExampleOfWork() {
      this.item.examplesOfWorks.push({
        ...this.newExampleOfWork,
        uuid: v4()
      })
      this.newExampleOfWork = {after: null, before: null, description: null}
    },
    removeExampleOfWork(item) {
      const idx = this.item.examplesOfWorks.indexOf(item)
      this.item.examplesOfWorks.splice(idx, 1)
    }
  }
}
</script>
