<template>
  <div class="doctor-promo">

    <v-btn
      v-if="!innerValue"
      @click="create"
      color="primary"
      large
    >
      <v-icon left>mdi-plus</v-icon>
      Создать блок
    </v-btn>

    <div class="doctor-promo__block" v-else>
      <div class="doctor-promo__block-head">
        <v-btn
          color="warning"
          @click="remove"
          style="margin: 0 0 0 auto; display: block"
        >
          <v-icon left small>mdi-close</v-icon>
          Удалить блок
        </v-btn>
      </div>

      <DoctorPromoForm v-model="innerValue" />

    </div>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import DoctorPromoForm, { DoctorPromoModel } from './DoctorPromoForm.vue'

function ServicePromo (options = {}) {
  return Object.assign({
    buttonText: null,
    title: null,
    canSendRequest: null,
    requestButtonLabel: null,
    content: null
  }, options)
}

export default {
  components: {
    DoctorPromoForm
  },
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      innerValue: null
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler (value) {
        if (isEqual(value, this.innerValue)) return
        this.innerValue = value
      }
    },
    innerValue: {
      immediate: true,
      deep: true,
      handler (innerValue) {
        this.$emit('input', innerValue)
      }
    }
  },
  methods: {
    create () {
      this.innerValue = new DoctorPromoModel()
    },
    remove () {
      if (!confirm('Удалить блок?')) return
      this.innerValue = null
    }
  }
}
</script>

<style lang="scss" scoped>
.doctor-promo__create {
  padding: 40px;
  border: 2px solid #1976d2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(#1976d2, 0.05);
}

.doctor-promo__create-description {
  margin-bottom: 20px;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
}
</style>
