var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"claims-list"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.create}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Добавить ")],1),(_vm.claims.length)?_c('v-data-table',_vm._g({staticClass:"mt-4",attrs:{"disable-sort":"","hide-default-footer":"","items":_vm.claims,"headers":[
      { value: 'code', text: 'Код' },
      { value: 'title', text: 'Название' },
      { value: 'channelId', text: 'Группа' },
      { value: 'comment', text: 'Комментарий' },
      { value: 'actions', text: '' }
    ]},scopedSlots:_vm._u([{key:"item.comment",fn:function(ref){
    var item = ref.item;
return [_c('claims-list-item-comment',{attrs:{"comment":item.comment}})]}},{key:"item.channelId",fn:function(ref){
    var item = ref.item;
return [_c('claims-list-item-channel',{attrs:{"item":item}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-trash")])],1)],1)]}}],null,false,2565384954)},_vm.$listeners)):_vm._e(),(_vm.modal)?_c('v-dialog',{attrs:{"value":true,"max-width":"580"},on:{"click:outside":_vm.close}},[_c('div',{staticClass:"title mb-8"},[_vm._v(" "+_vm._s(_vm.claim ? "Редактирование" : "Новая заявка")+" ")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var handleSubmit = ref.handleSubmit;
return [(_vm.claim)?_c('claim-editor',{attrs:{"autofocus":""},model:{value:(_vm.claim),callback:function ($$v) {_vm.claim=$$v},expression:"claim"}}):_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('claim-select',{attrs:{"label":"Тип заявки","dense":"","outlined":"","return-object":"","error-messages":errors[0]},model:{value:(_vm.claim),callback:function ($$v) {_vm.claim=$$v},expression:"claim"}})]}}],null,false,1796652579)}),_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v(" Сохранить ")]),_c('v-btn',{on:{"click":_vm.close}},[_vm._v("Close")])],1)]}}],null,false,3100490441)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }