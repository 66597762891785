<template>
  <div class="claims-list">
    <v-btn color="primary" @click="create">
        <v-icon small left>mdi-plus</v-icon>
        Добавить
      </v-btn>

    <v-data-table
      class="mt-4"
      v-if="claims.length"
      disable-sort
      hide-default-footer
      :items="claims"
      v-on="$listeners"
      :headers="[
        { value: 'code', text: 'Код' },
        { value: 'title', text: 'Название' },
        { value: 'channelId', text: 'Группа' },
        { value: 'comment', text: 'Комментарий' },
        { value: 'actions', text: '' }
      ]"
    >
      <template v-slot:item.comment="{ item }">
        <claims-list-item-comment :comment="item.comment" />
      </template>
      <template v-slot:item.channelId="{ item }">
        <claims-list-item-channel :item="item" />
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <v-btn icon @click="edit(item)">
            <v-icon small>fa-pencil</v-icon>
          </v-btn>
          <v-btn icon @click="remove(item)">
            <v-icon small>fa-trash</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-dialog
      v-if="modal"
      @click:outside="close"
      :value="true"
      max-width="580"
    >
      <div class="title mb-8">
        {{ claim ? "Редактирование" : "Новая заявка" }}
      </div>

      <validation-observer  v-slot="{ handleSubmit }">
        <claim-editor
          v-if="claim"
          v-model="claim"
          autofocus
        />
        <validation-provider rules="required" v-slot="{ errors }" v-else>
          <claim-select
            label="Тип заявки"
            dense
            outlined
            return-object
            v-model="claim"
            :error-messages="errors[0]"
          />
        </validation-provider>

        <div class="d-flex justify-space-between">
          <v-btn @click="handleSubmit(submit)" color="success">
            Сохранить
          </v-btn>
          <v-btn @click="close">Close</v-btn>
        </div>
      </validation-observer>
    </v-dialog>

  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import requestFormService from '@/services/request-form'
import ClaimSelect from './ClaimSelect.vue'
import ClaimsListItemChannel from './ClaimsListItemChannel.vue'
import ClaimsListItemComment from '../../components/Claim/ClaimsListItemComment.vue'
import ClaimEditor from './ClaimEditor.vue'

export default {
  model: {
    prop: 'claims',
    event: 'input'
  },
  components: {
    ClaimEditor,
    ClaimsListItemChannel,
    ClaimsListItemComment,
    ClaimSelect
  },
  props: {
    claims: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      claim: null,
      modal: false,
      modalMode: null,
      innerValue: []
    }
  },
  watch: {
    claims: {
      immediate: true,
      handler (claims) {
        if (isEqual(claims, this.innerValue)) return
        this.innerValue = claims
      }
    },
    innerValue (innerValue) {
      this.$emit('input', innerValue)
    }
  },
  methods: {
    create (options) {
      return requestFormService.createModel(options)
    },
    update (idx, claim) {
      this.$set(this.innerValue, idx, claim)
      this.modal = false
      this.claim = null
    },
    add () {
      this.innerValue.push({
        id: Math.random(),
        ...this.claim
      })
      this.modal = false
      this.claim = null
    },
    close () {
      this.modal = false
      this.claim = null
    },
    remove (item) {
      if (!confirm('Удалить?')) {
        return
      }
      const idx = this.innerValue.findIndex(({ id }) => (id === item.id))
      if (idx > -1) {
        this.$delete(this.innerValue, idx)
      }
    },
    create () {
      this.modalMode = 'create'
      this.claim = null
      this.modal = true
    },
    edit (claim) {
      this.modalMode = 'edit'
      this.claim = claim
      this.modal = true
    },
    submit () {
      // const code = this.claim.code
      if (this.modalMode === 'edit') {
        const idx = this.innerValue.findIndex((item) => (item.code === this.claim.code))
        this.update(idx, this.claim)
      }
      if (this.modalMode === 'create') {
        this.add(this.claim)
      }
    },
  }
}
</script>
